<template>
  <div>
    <b-button
      v-if="showView"
      variant="warning ml-1 mt-1"
      @click="handleView"
    >
      View
    </b-button>
    <b-button
      v-if="showEdit"
      variant="primary ml-1 mt-1"
      @click="handleEdit"
    >
      Edit
    </b-button>
    <b-button
      v-if="showDelete"
      variant="danger ml-1 mt-1"
      @click="handleDelete"
    >
      Delete
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  props: {
    showView: {
      type: Boolean,
    },
    showEdit: {
      type: Boolean,
    },
    showDelete: {
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    handleView() {
      this.$emit('view-clicked')
    },
    handleEdit() {
      this.$emit('edit-clicked')
    },
    handleDelete() {
      this.$emit('delete-clicked')
    },
  },
}
</script>
