<template>
  <section v-if="dataLoaded">

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Companies
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="AwardIcon"
            size="24"
          />
          Companies
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >

        <b-button
          variant="primary"
          :to="{name: 'create-company'}"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button>
      </b-col>
    </b-row>

    <!-- table row  -->
    <b-card>
      <b-row v-if="tableItems.length <= 0">
        <b-col>
          No items to show.
        </b-col>
      </b-row>
      <b-row v-if="tableItems.length > 0">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="perPageSelect"
            >Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="sortBySelect"
            >Sort</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    Select
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="filterInput"
            >Filter</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12 mt-2">
          <b-table
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableItems"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(actions)="data">
              <table-action-buttons
                :show-view="true"
                :show-edit="true"
                :show-delete="true"
                @view-clicked="handleItemView(data)"
                @edit-clicked="handleItemEdit(data)"
                @delete-clicked="handleItemDelete(data)"
              />
            </template>

            <template #cell(created_at)="data">
              {{ getReadableDate(data.item.created_at) }}
            </template>

            <template #cell(updated_at)="data">
              {{ getReadableDate(data.item.updated_at) }}
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BTable, BFormGroup, BFormSelect, BPagination,
  BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import TableActionButtons from '@/views/components/action-buttons/TableActionButtons.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    TableActionButtons,
  },
  data() {
    return {
      perPage: 75,
      pageOptions: [5, 10, 15, 30, 45, 75],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'updated_at', label: 'Updated At', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      tableItems: [],
      dataLoaded: false,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  beforeCreate() {
    useJwt.getAllCompanies()
      .then(response => {
        const resourcesData = response.data.data
        this.tableItems = resourcesData
        // Set the initial number of items
        this.totalRows = this.tableItems.length
        // indidate that data fetch was successful
        this.dataLoaded = true
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleItemView(data) {
      const tableItem = data.item
      // navigate to the view page for the given resource id
      this.$router.push({ name: 'view-company', params: { id: tableItem.id } })
    },
    handleItemEdit(data) {
      const tableItem = data.item
      // navigate to the edit page for the given resource id
      this.$router.push({ name: 'edit-company', params: { id: tableItem.id } })
    },
    handleItemDelete(data) {
      const tableItem = data.item
      console.log(tableItem)
    },
    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
  },
}
</script>

<style lang="scss">

</style>
